<template>
    <div class="container">
        <div class="row mt-5 pt-5">
            <div v-if="userStatus == 4" class="mt-5 pt-5 col-12 d-flex justify-content-center align-items-center flex-column">
                <h1 class="text-center mb-4">Country Mismatch</h1>
                <p class="text-center">Your account has been temporarily blocked because the country you entered during registration does not match the country you entered during the KYC process. <br> Please contact our help center at <a style="text-decoration: underline" href="mailto:help@help.com">help@help.com</a> for further assistance</p>
            </div>
            <div v-if="userStatus == 3" class="mt-5 pt-5 col-12 d-flex justify-content-center align-items-center flex-column">
                <h1 class="text-center mb-4">KYC Cancelled</h1>
                <p class="text-center">KYC process has been cancelled. <br> Please contact our help center at <a style="text-decoration: underline" href="mailto:help@help.com">help@help.com</a> for further assistance</p>
            </div>
        </div>
    </div>
</template>se

<script>
import { defineComponent } from "vue";
// import config from "../../config";

export default defineComponent({
    name: 'KYC Error',
    data: function() {
        return {
            userStatus: null
        }
    },
    mounted: function() {
        this.userStatus = this.$route.params.status;
    }
});

</script>